import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setLoadingState: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingState } = requestSlice.actions;

export const isRequestLoadingSelector = (state) => state.request.isLoading;

export const requestReducer = requestSlice.reducer;
