import { createAsyncThunk } from '@reduxjs/toolkit';

import { formatData } from 'utils/product';
import { createApiRequest } from 'utils/request';

export const getFillings = createAsyncThunk('fillings/getFillings', async (params, thunkApi) => {
  const response = await createApiRequest(
    {
      url: `/api/fillings`,
    },
    thunkApi
  );

  return formatData(response?.data?.data || [], { uploadsPath: 'fillings/' });
});

export const addFilling = createAsyncThunk(
  'fillings/addFilling',
  async ({ successCallback = () => {}, failureCallback = () => {}, ...params }, thunkApi) => {
    const fillings = thunkApi.getState().fillings.data;
    const response = await createApiRequest(
      {
        method: 'post',
        url: `/api/fillings`,
        data: params,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
      thunkApi
    );

    successCallback();

    return formatData(fillings, { add: response?.data?.data || [], uploadsPath: 'fillings/' });
  }
);

export const updateFilling = createAsyncThunk(
  'fillings/updateFilling',
  async ({ id, successCallback = () => {}, failureCallback = () => {}, ...params }, thunkApi) => {
    const fillings = thunkApi.getState().fillings.data;
    const response = await createApiRequest(
      {
        method: 'post',
        url: `/api/fillings/${id}?_method=PUT`,
        headers: {
          'Content-type': 'multipart/form-data',
        },
        data: params,
      },
      thunkApi
    );

    if (response?.status === 200) {
      successCallback();

      return formatData(fillings, { update: response?.data?.data[0], uploadsPath: 'fillings/' });
    }

    return {};
  }
);

export const deleteFilling = createAsyncThunk(
  'fillings/deleteFilling',
  async ({ id, photo_uri, successCallback = () => {}, failureCallback = () => {} } = {}, thunkApi) => {
    const fillings = thunkApi.getState().fillings.data;
    await createApiRequest(
      {
        method: 'delete',
        url: `/api/fillings/${id}`,
        params: { photo_uri },
      },
      thunkApi
    );

    successCallback();

    return formatData(fillings, { delete: [id], uploadsPath: 'fillings/' });
  }
);
