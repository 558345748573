import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getFillings, updateFilling, addFilling, deleteFilling } from 'api/fillingsApi';

const initialState = {
  data: [],
  loading: false,
  errorMessage: null,
  entities: {},
  ids: [],
  errors: {},
};

export const fillingsSlice = createSlice({
  name: 'fillings',
  initialState,
  extraReducers: {
    [updateFilling.fulfilled]: (state, { payload }) => {
      state.ids = payload.ids;
      state.entities = payload.entities;
      state.data = payload.data;
    },

    // ADD
    [addFilling.pending]: (state) => ({ ...state, loading: true }),
    [addFilling.fulfilled]: (state, { payload }) => {
      if (payload?.error) {
        return state;
      }

      state.loading = false;
      state.ids = payload.ids;
      state.entities = payload.entities;
      state.data = payload.data;
    },
    [addFilling.rejected]: (state, error) => {
      state.loading = false;
      state.errorMessage = error?.message;
    },

    // DELETE
    [deleteFilling.fulfilled]: (state, { payload }) => {
      state.ids = payload.ids;
      state.entities = payload.entities;
      state.data = payload.data;
    },

    // GET
    [getFillings.pending]: (state) => ({ ...state, loading: true }),
    [getFillings.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },
    [getFillings.rejected]: (state, payload) => ({ ...state, error: payload, loading: false }),
  },
});

const fillingsState = (state) => state.fillings;

export const getFillingsStateSelector = createSelector(fillingsState, (state) => state);

export const getIsFillingsLoadedSelector = createSelector(fillingsState, ({ loading }) => loading);

export const getFillingsSelector = createSelector(fillingsState, (state) => {
  const fillings = state?.ids?.map((id) => state?.entities[id]) || [];

  return (
    {
      fillings,
    } || {}
  );
});

export const fillingsReducer = fillingsSlice.reducer;
