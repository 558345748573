import { Header } from 'components/Header';

export const Layout = ({ withHeader, children }) => {
  return (
    <div>
      {withHeader ? <Header /> : null}

      {children}
    </div>
  );
};
