import { createSlice } from '@reduxjs/toolkit';
import { signOut } from 'firebase/auth';
import { removeItemStorage } from 'utils/localStorage';
import { auth } from 'config/firebase';

const initialState = {
  isLogged: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: async (state) => {
      await signOut(auth);

      removeItemStorage('userToken');

      return state;
    },
  },
});

export const { logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
