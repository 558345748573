import styled, { css } from 'styled-components';
import { Icon } from 'components/shared/Icon';

export const IconStyled = styled(Icon)``;

const getButtonTypeStyles = ({ styleType, withIcon }) => {
  if (styleType === 'primary') {
    return css`
      background-color: #1677ff;
      box-shadow: 0 2px 0 rgb(5 145 255 / 10%);

      ${IconStyled} svg {
        color: #fff;
      }

      &:hover {
        background-color: #4096ff;
      }

      &:active {
        background-color: #0958d9;
      }

      &:focus {
        border-color: var(--form-border-color-active);
        box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
      }
    `;
  }

  if (styleType === 'secondary') {
    return css`
      background-color: #fff;
      border-color: #d9d9d9;
      box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
      color: rgba(0, 0, 0, 0.88);

      &:hover {
        color: #4096ff;
        border-color: #4096ff;

        ${IconStyled} svg {
          transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          color: #4096ff;
        }
      }

      &:active {
        color: #0958d9;
        border-color: #0958d9;
      }

      &:focus {
        border-color: var(--form-border-color-active);
        box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
      }
    `;
  }

  if (styleType === 'danger') {
    return css`
      background-color: #ff4d4f;
      box-shadow: 0 2px 0 rgb(255 38 5 / 6%);

      ${IconStyled} svg {
        color: #fff;
      }

      &:hover {
        background-color: #ff7875;
      }

      &:active {
        background-color: #d9363e;
      }
    `;
  }
};

const defaultStyles = css`
  display: inline-block;
  line-height: 1.5714285714285714;
  font-size: 14px;
  padding: 4px 20px;
`;

const withIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px;
  width: 32px;
`;

const withIconAndCTextStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px 4px 8px;
  gap: 5px;
`;

export const ButtonStyled = styled.button`
  outline: none;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  border-radius: 3px;
  color: #fff;

  ${({ withIcon, hasChildren }) => {
    if (withIcon && hasChildren) {
      return withIconAndCTextStyles;
    }

    if (withIcon) {
      return withIconStyles;
    }

    return defaultStyles;
  }}

  ${({ styleType, disabled, withIcon }) => !disabled && getButtonTypeStyles({ styleType, withIcon })}

  &:focus {
    border-inline-end-width: 1px;
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      border-color: #d9d9d9;
      color: rgba(0,0,0,.25);
      background-color: rgba(0,0,0,.04);
      box-shadow: none;
  `}
`;
