import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapperStyle = {
  display: 'none',
  position: 'absolute',
  border: 0
};

export const UploadWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey);
  box-shadow: 0 0 0 1px #d9d9d9;
  border-radius: 3px;
  min-height: 230px;
  margin-bottom: 15px;
  cursor: pointer;
  overflow: hidden;
`;

export const Preview = styled.img`
  max-width: 100%;
`;
