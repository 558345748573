import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { getCurrentUserSelector, setUserAction } from 'store/userSlice';

import { removeItemStorage } from 'utils/localStorage';

import { apiLoginStatus } from 'api/authApi';

import { Layout } from './Layout';

const auth = getAuth();

export const ProtectedRoute = ({ component, navigateTo = '/' }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const currentUserState = useSelector(getCurrentUserSelector);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!!currentUser?.uid && !currentUserState) {
        const { uid, email, displayName, photoURL, emailVerified } = currentUser;

        if (uid) {
          try {
            const response = await apiLoginStatus();

            if (response?.data?.status === 'success') {
              dispatch(setUserAction({ uid, email, displayName, photoURL, emailVerified }));
            }
          } catch (error) {
           
            removeItemStorage('userToken');
            dispatch(setUserAction({}));

            navigate('/login');

            return;
          }
        }
      }

      if (!currentUser?.uid) {
        navigate('/login');

        return;
      }

      setIsAuthorized(!!currentUser?.uid);

      if (pathname === '/' && !!currentUser?.uid) {
        navigate('/cakes');

        return;
      }

      navigate(navigateTo);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserState, navigate]);

  if (!isAuthorized) {
    return null;
  }

  const withHeader = pathname !== '/';

  return <Layout withHeader={withHeader}>{component}</Layout>;
};
