import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { Loader } from 'components/shared/Loader';

import { isRequestLoadingSelector } from 'store/requestSlice';

import { Wrapper } from './PageContainerStyled';

export const PageContainer = ({ children, title = '' }) => {
  const isRequestLoading = useSelector(isRequestLoadingSelector);

  return (
    <Wrapper>
      <Helmet title={`${title} - MyCake Admin`} />
      <Loader isVisible={isRequestLoading} />
      {children}
    </Wrapper>
  );
};
