import { useState } from 'react';
import { Wrapper, TextareaStyled, Label } from './TextareaStyled';

export const Textarea = ({ after = null, before = null, width = null, label = null, ...props }) => {
  const [focused, setFocused] = useState(false);

  const handleFocused = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <Wrapper width={width} focused={focused} withLabel={!!label}>
      {label ? <Label>{label}</Label> : null}
      <TextareaStyled onFocus={handleFocused} onBlur={handleBlur} {...props} />
    </Wrapper>
  );
};
