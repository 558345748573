import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 3px;
  position: relative;
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  gap: 15px;

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ImageWrapper = styled.div`
  margin-top: -1px;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  margin-bottom: auto;

  ${({ isSelected }) => {
    if (isSelected) {
      return `
        border: 5px solid #49b464;
        
        img {
          max-width: 270px !important;
        }
      `;
    }
  }}

  img {
    height: auto;
    vertical-align: top;
    width: 100%;
  }
`;

export const Item = styled.div`
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 8%);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ItemActions = styled.div`
  background-color: white;
  display: flex;
  gap: 10px;
  flex-shrink: 0;
  padding: 10px 15px;
  border-radius: 0 0 3px 3px;
  justify-content: space-between;
`;

export const ItemName = styled.div`
  font-weight: 600;
  padding-bottom: 5px;
  text-align: center;
  color: var(--color-grey-text);
  font-size: 12px;
  display: flex;
  gap: 10px;
`;

export const ItemValue = styled.div`
  font-weight: 600;
  color: var(--color-black);

  ${({ ellipsis }) =>
    ellipsis &&
    `
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
  `}
`;

export const ItemDescription = styled.div`
  padding: 5px 15px 0;
  background-color: white;
`;

export const Status = styled.div`
  color: var(--color-black);
`;
