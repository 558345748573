import { createSlice, createSelector } from '@reduxjs/toolkit';
import { deleteProduct, getProducts, updateProduct, addProduct } from 'api/productsApi';

const initialState = {
  data: [],
  loading: false,
  errorMessage: null,
  entities: {},
  ids: [],
  page: 0,
  total: 0,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetErrorMessage: (state) => {
      return {
        ...state,
        errorMessage: null,
      };
    },
  },
  extraReducers: {
    [updateProduct.fulfilled]: (state, { payload: { id, ...updates } }) => {
      state.entities[id] = {
        ...state.entities[id],
        ...updates,
      };
    },

    // ADD
    [addProduct.pending]: (state) => ({ ...state, loading: true }),
    [addProduct.fulfilled]: (state, { payload }) => {
      if (payload?.error) {
        return { ...state, errorMessage: payload.error, loading: false };
      }
      state.loading = false;
      state.ids = payload.ids;
      state.entities = payload.entities;
      state.data = payload.data;
      state.page = payload.page;
      state.total = payload.total;
    },
    [addProduct.rejected]: (state, {payload}) => {
      state.loading = false;
      state.errorMessage = payload?.error;
    },

    // DELETE
    [deleteProduct.fulfilled]: (state, { payload }) => {
      state.ids = payload.ids;
      state.entities = payload.entities;
      state.data = payload.data;
    },

    // GET
    [getProducts.pending]: (state) => ({ ...state, loading: true }),
    [getProducts.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, ...payload.data };
      state.entities = { ...state.entities, ...payload.entities };
      state.ids = payload.ids;
      state.total = payload.total;
      state.page = payload.page;
      state.loading = false;
    },
    [getProducts.rejected]: (state, payload) => ({ ...state, error: payload, loading: false }),
  },
});

const productState = (state) => state.product;

export const getProductStateSelector = createSelector(productState, (state) => state);

export const getIsProductLoadedSelector = createSelector(productState, ({ loading }) => loading);

export const getProductSelector = createSelector(productState, (state) => {
  const products = state?.ids?.map((id) => state?.entities[id]) || [];

  return (
    {
      products,
      page: state.page,
      total: state.total,
    } || {}
  );
});

export const { resetErrorMessage } = productSlice.actions;
export const productReducer = productSlice.reducer;
