import { PageContainer } from 'components/PageContainer';
import { CronJobs } from 'components/CronJobs';

export const CronJobsPage = ({ title }) => {
  

  return (
    <PageContainer title={title}>
      <h2>Cron jobs</h2>

      <CronJobs />
      
    </PageContainer>
  );
};
