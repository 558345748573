import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';
import { Input } from 'components/shared/Input';

import { getFillingsStateSelector } from 'store/fillingsSlice';

import { addFilling, updateFilling } from 'api/fillingsApi';

import { Wrapper, UploadWrapper, Preview, InputWrapperStyle } from './AddFillingModalStyled';
import { Textarea } from 'components/shared/Textarea';

export const AddFillingModal = ({ isVisible, initialValues, onClose }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [errors, setErrors] = useState(null);
  const [fields, setFields] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const { loading } = useSelector(getFillingsStateSelector);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors((errors) => ({
      ...errors,
      title: !fields?.title ? 'Потрібно додати назву' : null,
    }));

    const action = isEdit ? updateFilling : addFilling;

    if ((fields?.image || (fields?.url && isEdit)) && fields?.title) {
      dispatch(
        action({
          ...fields,
          prevImage: fields?.photo_uri,
          successCallback: () => {
            if (!loading) {
              handleModalClose();
            }
          },
          failureCallback: ({ error }) => {
            setErrors((errors) => ({
              ...errors,
              default: error?.message,
            }));
          },
        })
      );
    }
  };

  const handleFieldChange = ({ target }) => {
    if (target.name === 'image') {
      setFields((fields) => ({ ...fields, image: target.files[0] }));
    } else {
      setFields((fields) => ({ ...fields, [target.name]: target.value }));
    }

    setErrors((errors) => ({
      ...errors,
      [target.name]: null,
    }));
  };

  const handleReset = () => {
    setErrors(null);
    setFields(null);
    setIsEdit(false);
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setFields(null);
    setErrors(null);
    setIsEdit(false);
    setModalVisible(false);
    onClose();
  };

  useEffect(() => {
    if (isVisible !== undefined) {
      setModalVisible(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    if (initialValues) {
      setFields(initialValues);
      setIsEdit(true);
    }
  }, [initialValues]);

  const imageEditUrl = fields?.url ? fields?.url : null;
  const imageUrl = fields?.image ? URL.createObjectURL(fields?.image) : null;
  const image = imageUrl || imageEditUrl;

  return (
    <Wrapper>
      <Button icon='add' onClick={handleModalOpen}>
        Додати
      </Button>
      <Modal
        visible={modalVisible}
        onSubmit={handleSubmit}
        onReset={handleReset}
        onClose={handleModalClose}
        resetButtonProps={{
          disabled: !image && !fields,
        }}
        submitButtonProps={{
          disabled: errors?.file || loading || (!fields?.image && !isEdit),
        }}
      >
        {errors?.default && <>{errors.default}</>}
        <UploadWrapper htmlFor='input-file-upload'>
          <Input wrapperStyle={InputWrapperStyle} type='file' onChange={handleFieldChange} id='input-file-upload' name='image' />

          {image ? null : <span>Вибрати зображення</span>}

          {image ? <Preview src={image} alt='Thumb' /> : null}
        </UploadWrapper>

        <Input
          error={errors?.title}
          label='Назва *'
          placeholder='Назва'
          name='title'
          value={fields?.title || ''}
          onChange={handleFieldChange}
        />

        <Textarea label='Опис' name='description' value={fields?.description || ''} onChange={handleFieldChange} />
      </Modal>
    </Wrapper>
  );
};
