import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

import { apiLogin } from 'api/authApi';

import { setUserAction } from 'store/userSlice';

import { removeItemStorage, setItemStorage } from 'utils/localStorage';

import { auth } from 'config/firebase';

export const useUserAuth = () => {
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  async function logIn({ email, password, successCallback, errorCallback }) {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password);

      if (data?.user) {
        setUser(data?.user);

        const { uid, email, displayName, photoURL, emailVerified } = data.user;

        const loginData = await apiLogin({
          email,
          password,
        });

        if (loginData?.status === 200) {
          const {
            data: {
              user: { id, name },
              authorization: { token },
            },
          } = loginData;

          dispatch(setUserAction({ uid, email, displayName, photoURL, emailVerified, id, name, token }));

          setItemStorage({ key: 'userToken', value: token });

          successCallback(data);
        }
        successCallback(data);
      }
    } catch (error) {
      removeItemStorage('userToken');

      if (error.code === 'ERR_BAD_REQUEST' && error.name === 'AxiosError') {
        errorCallback({ message: 'Error login to server. Check your password or email' });

        return;
      }

      errorCallback(error);
    }
  }

  async function logOut({ successCallback, errorCallback }) {
    try {
      await signOut(auth);

      removeItemStorage('userToken');

      dispatch(setUserAction({}));

      successCallback();
    } catch (error) {
      errorCallback(error);
    }
  }

  return { logIn, logOut, user };
};
