import axios from 'axios';
import { getItemStorage } from 'utils/localStorage';

export const apiRegister = async (params) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/register`, params);
};

export const apiLogin = async ({ email, password } = {}) => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/generateAccessToken`, {
    email,
    password,
  });
};

export const apiLoginStatus = () =>
  axios.get(`${process.env.REACT_APP_API_URL}/api/v1/me`, {
    headers: {
      Authorization: `Bearer ${getItemStorage('userToken')}`,
    },
  });

export const apiRefreshToken = async () => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/refreshAccessToken`);
};
