import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import { Box } from 'components/shared/Box';
import { AddProductModal } from 'components/AddProductModal';

import { deleteProduct, updateProduct, deleteProducts } from 'api/productsApi';

import { PRODUCT_TYPES_LABELS, VISIBILITY_TYPES, VISIBILITY_TYPES_TITLES } from 'constants/products';

import { Wrapper, Item, ItemName, ImageWrapper, ItemActions, ItemDescription, ItemValue } from './ProductsListStyled';
import { ConfirmModal } from 'components/shared/ConfirmModal';

export const ProductsList = ({ items = [], onDelete = () => {}, total = 0, isNoProducts = false }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isConfirmBulkDeleteModalVisible, setIsConfirmBulkDeleteModalVisible] = useState(false);

  const handleDelete = () => {
    const { id, photo_uri } = editItem;

    dispatch(
      deleteProduct({
        id,
        photo_uri,
        successCallback: () => {
          setModalVisible(false);
          onDelete({ isNeedToLoadMore: false });
        },
      })
    );
  };

  const handleVisibilityChange =
    ({ id, visibility }) =>
    () => {
      const isVisible = visibility === VISIBILITY_TYPES.visible;

      dispatch(
        updateProduct({
          id,
          visibility: isVisible ? VISIBILITY_TYPES.hidden : VISIBILITY_TYPES.visible,
        })
      );
    };

  const handleModalOpen = (item) => () => {
    setModalVisible(true);
    setEditItem(item);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const getVisibilityButtonText = (visible) => (visible === VISIBILITY_TYPES.hidden ? 'Показати' : 'Приховати');

  const handleProductClick = (item) => () => {
    const products = [...selectedProducts];

    if (products.includes(item.photo_uri)) {
      setSelectedProducts(products.filter((id) => id !== item.photo_uri));
    } else {
      setSelectedProducts([...selectedProducts, item.photo_uri]);
    }
  };

  const handleProductsDeleteClick = () => {
    setIsConfirmBulkDeleteModalVisible(true);
  };

  const handleBulkDelete = () => {
    dispatch(deleteProducts({ productsIds: selectedProducts }));
    setSelectedProducts([]);
    setIsConfirmBulkDeleteModalVisible(false);
    onDelete({ isNeedToLoadMore: false });
  };

  const handleProductsDeleteCancelClick = () => {
    setSelectedProducts([]);
  };

  return (
    <>
      <Box gap='20px' alignItems='center' marginBottom='20px'>
        <AddProductModal />
        {selectedProducts.length > 0 ? (
          <Box gap='10px'>
            <Button icon='delete' styleType='danger' onClick={handleProductsDeleteClick}>
              Видалити {selectedProducts.length} товарів
            </Button>
            <Button icon='close' styleType='secondary' onClick={handleProductsDeleteCancelClick}>
              Відмінити вибір
            </Button>
          </Box>
        ) : null}

        <Box marginLeft='auto'>
          Всього:&nbsp;<strong>{total}</strong>
        </Box>
      </Box>

      {isNoProducts ? <Box>Немає товарів</Box> : null}

      <Wrapper>
        {/* {errorMessage ? <p>{errorMessage}</p> : null} */}
        {items.map((item, index) => {
          return (
            <Item key={index}>
              <ImageWrapper isSelected={selectedProducts.includes(item.photo_uri)} onClick={handleProductClick(item)}>
                <img alt='' src={item.thumbUrl} />
              </ImageWrapper>

              <ItemDescription>
                <ItemName>
                  Назва файлу: <ItemValue ellipsis>{item.photo_uri}</ItemValue>
                </ItemName>
                <ItemName>
                  Категорія: <ItemValue>{PRODUCT_TYPES_LABELS?.[item?.cake_type]}</ItemValue>
                </ItemName>
                <ItemName>
                  Видимість на сайті: <ItemValue>{VISIBILITY_TYPES_TITLES[item?.visibility]}</ItemValue>
                </ItemName>
              </ItemDescription>

              <ItemActions>
                <Button styleType='secondary' onClick={handleVisibilityChange(item)}>
                  {getVisibilityButtonText(item?.visibility)}
                </Button>
                <Button styleType='danger' icon='delete' onClick={handleModalOpen(item)} title='Видалити' />
              </ItemActions>
            </Item>
          );
        })}

        <Modal visible={modalVisible} withClose contentCenter submitText='Так' onClose={handleModalClose} onSubmit={handleDelete}>
          Ви впевнені?
        </Modal>
      </Wrapper>

      {isConfirmBulkDeleteModalVisible ? (
        <ConfirmModal
          isVisible={isConfirmBulkDeleteModalVisible}
          onCancel={() => setIsConfirmBulkDeleteModalVisible(false)}
          onConfirm={handleBulkDelete}
        >
          <Box alignItems='center' height='100%'>
            Ви впевнені, що хочете видалити {selectedProducts.length} товарів?
          </Box>
        </ConfirmModal>
      ) : null}
    </>
  );
};
