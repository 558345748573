import styled from 'styled-components';

export const ProgressWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  top: -20px;
`;

export const PercentValue = styled.div`
  position: absolute;
  height: 100%;
  background-color: var(--color-primary);
  width: ${({ percent }) =>percent ? `${percent}%` : 0};
`;
