import { useState } from 'react';
import { format, fromUnixTime } from 'date-fns';
import { uk } from 'date-fns/esm/locale';

import { getJobDetails } from 'api/cronApi';

import { Button } from 'components/shared/Button';

import {ButtonsStyled, Status} from './JobStyled'

export const Job = ({ title, jobId: id, onDelete, onChange, enabled }) => {
  const [details, setDetails] = useState(null);

  const handleDetails = () => {
    getJobDetails({ id }).then(({ data }) => {
      setDetails(data?.jobDetails);
    });
  };

  const nextStart = details?.nextExecution ? format(fromUnixTime(details?.nextExecution), 'dd MMMM yyyy - HH:mm:ss', { locale: uk }) : null;

  return (
    <div
      style={{
        padding: 20,
        border: '1px solid #dedede',
        margin: '10px 0',
      }}
    >
      <h3>{title} <Status isActive={enabled} /></h3>
     
      <ButtonsStyled>
      <Button onClick={onChange({ id, enabled: !enabled })}>{enabled ? 'Вимкнути' : 'Увімкнути'}</Button>
      <Button icon="delete" onClick={onDelete({ id })} styleType="danger" />
      </ButtonsStyled>

      <Button onClick={handleDetails}>Деталі</Button>

      {nextStart && <p>Наступний запуск - {nextStart}</p>}
    </div>
  );
};
