import styled from 'styled-components';

export const Wrapper = styled.div`

`;

export const UploadWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey);
  box-shadow: 0 0 0 1px #d9d9d9;
  border-radius: 3px;
  width: 300px;
  min-height: 230px;
  margin-bottom: 15px;
  cursor: pointer;
  overflow: hidden;

  input {
    display: none;
    position: absolute;
    border: 0;
  }
`;


export const Preview = styled.img`
    max-width: 100%;
`

export const RadioLabel = styled.label`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  &:hover span {
    color: var(--color-primary);
  }
`
