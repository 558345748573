import styled, { css } from 'styled-components';

export const IconStyled = styled.svg`
  color: ${({ color }) => color};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  ${({ isHovered, isClickable }) =>
    (isHovered || isClickable) &&
    css`
      cursor: pointer;
    `}

  &:hover {
    color: ${({ hoverColor, isHovered }) => isHovered && hoverColor};
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;
