import { configureStore } from '@reduxjs/toolkit';
// import { setupListeners } from '@reduxjs/toolkit/query';
// import { persistReducer } from 'redux-persist';
// import localForage from 'localforage';

import { userReducer } from './userSlice';
import { productReducer } from './productSlice';
import { requestReducer } from './requestSlice';
import { pricesReducer } from './pricesSlice';
import { fillingsReducer } from './fillingsSlice';
import { contentReducer } from './contentSlice';
import { notifyReducer } from './notifySlice';
import { authReducer } from './authSlice';

// localForage.config({
//   driver: localForage.INDEXEDDB,
//   name: 'MyProduct storage',
//   storeName: 'MyProduct',
// });

// const persistAuth = {
//   key: 'user',
//   storage: localForage,
//   whitelist: ['user.token'],
// };

export const store = configureStore({
  reducer: {
    user: userReducer,
    product: productReducer,
    request: requestReducer,
    prices: pricesReducer,
    fillings: fillingsReducer,
    content: contentReducer,
    notify: notifyReducer,
    auth: authReducer,
  },
});

// setupListeners(store.dispatch);
