import { Icon } from 'components/shared/Icon';
import { LoaderStyled, LoaderContent } from './LoaderStyled';

export const Loader = ({ isVisible = false }) => {
  return (
    <LoaderStyled isVisible={isVisible}>
      <LoaderContent>
        <Icon name='loader' color="#ffffff" size={40} />
      </LoaderContent>
    </LoaderStyled>
  );
};
