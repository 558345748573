import styled, { css } from 'styled-components';

export const InputStyled = styled.input`
  padding: 4px 11px;
  width: 100%;
  display: inline-block;
  outline: none;
  border: 0;
  background: transparent;
  line-height: 1.5714285714285714;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: var(--color-dark-grey);
  }
`;

export const Label = styled.label`
  position: absolute;
  top: -20px;
  font-size: 12px;
`;

export const ErrorLabel = styled.label`
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 11px;
  color: var(--color-danger);
`;

export const Wrapper = styled.div`
  border: 1px solid var(--form-border-color);
  display: flex;
  transition: border-color 0.2s;
  border-radius: 3px;
  width: ${({ width }) => width || 'auto'};

  ${({ focused }) =>
    focused &&
    css`
      border-color: var(--form-border-color-active);
      box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
      border-inline-end-width: 1px;
    `}

  ${({ withLabel }) =>
    withLabel &&
    css`
      margin-top: 25px;
      display: inline-block;
      width: 100%;
      position: relative;
    `}
  
  ${({ withError }) =>
    withError &&
    css`
      border-color: var(--form-border-color-error);
    `}
`;

export const AddonAfter = styled.span`
  padding: 4px 5px;
  color: rgba(0, 0, 0, 0.88);
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 1px solid var(--form-border-color);
`;
