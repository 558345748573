import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';

import { useUploadFile } from 'hooks/useUploadFile';

import { getProducts } from 'api/productsApi';

import { getProductStateSelector, resetErrorMessage } from 'store/productSlice';

import { DEFAULT_PRODUCT_TYPE, PRODUCTS_TYPES_GROUP } from 'constants/products';

import { Wrapper, UploadWrapper, Preview, RadioLabel } from './AddProductModalStyled';

export const AddProductModal = () => {
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [cakeType, setCakeType] = useState(DEFAULT_PRODUCT_TYPE);

  const { loading, errorMessage } = useSelector(getProductStateSelector);

  const { onUpload } = useUploadFile({ file, cakeType });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (file && cakeType) {
      onUpload({
        successCallback: () => {
          if (!loading) {
            setModalVisible(false);

            setFile(null);
            dispatch(getProducts());
          }
        },
      });
    }
  };

  const handleOnChange = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleTypeChange = ({ target }) => {
    setCakeType(target.value);
  };

  const handleReset = () => {
    setFile(null);
    setCakeType(DEFAULT_PRODUCT_TYPE);
    dispatch(resetErrorMessage());
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    handleReset();
    setModalVisible(false);
  };

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper data-id="add-product-modal">
      <Button icon='add' onClick={handleModalOpen}>
        Додати
      </Button>
      <Modal
        visible={modalVisible}
        onSubmit={handleSubmit}
        onReset={handleReset}
        onClose={handleModalClose}
        resetButtonProps={{
          disabled: !file,
        }}
        submitButtonProps={{
          disabled: loading || !file || errorMessage,
        }}
      >
        {errorMessage && <>{errorMessage}</>}

        <UploadWrapper htmlFor='input-file-upload'>
          <input type='file' onChange={handleOnChange} id='input-file-upload' name='file' />

          {file ? null : <span>Вибрати зображення</span>}

          {!file ? null : <Preview src={URL.createObjectURL(file)} alt='Thumb' />}
        </UploadWrapper>

        {Object.keys(PRODUCTS_TYPES_GROUP).map((key) => {
          return (
            <RadioLabel key={key}>
              <input onChange={handleTypeChange} type='radio' name='type' value={key} checked={cakeType === key} />{' '}
              <span>{PRODUCTS_TYPES_GROUP[key]}</span>
            </RadioLabel>
          );
        })}
      </Modal>
    </Wrapper>
  );
};
