import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 3px;
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  gap: 15px;
`;

export const ImageWrapper = styled.div`
  margin-top: -1px;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  margin-bottom: auto;
  flex-shrink: 0;

  img {
    height: auto;
    width: 280px;
    vertical-align: top;
  }
`;

export const Item = styled.div`
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 8%);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  
  @media (min-width: 768px) {
    flex-direction: row;
    
  }
`;

export const ItemActions = styled.div`
  background-color: white;
  display: flex;
  gap: 10px;
  flex-shrink: 0;
  padding: 10px 15px;
  border-radius: 0 0 3px 3px;
  justify-content: space-between;
  margin-left: auto;
`;

export const ItemName = styled.div`
  font-weight: 600;
  padding-bottom: 5px;
  color: var(--color-grey-text);
  font-size: 12px;
  display: flex;
  gap: 10px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const ItemValue = styled.div`
  font-weight: 600;
  color: var(--color-black);
  text-align: left;
`;

export const ItemDescription = styled.div`
  padding: 5px 15px 0;
  background-color: white;
  width: 100%;
`;
