import { useState } from 'react';
import { Wrapper, InputStyled, AddonAfter, Label, ErrorLabel } from './InputStyled';

export const Input = ({
  after = null,
  before = null,
  width = null,
  label = null,
  value = undefined,
  error = null,
  onChange,
  wrapperStyle = null,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocused = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <Wrapper width={width} focused={focused} withLabel={!!label} withError={!!error} style={wrapperStyle}>
      {label ? <Label>{label}</Label> : null}
      {before ? before : null}
      <InputStyled onFocus={handleFocused} onBlur={handleBlur} value={value} onChange={onChange} {...props} />
      {after ? <AddonAfter>{after}</AddonAfter> : null}
      {error ? <ErrorLabel>{error}</ErrorLabel> : null}
    </Wrapper>
  );
};
