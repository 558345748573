import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`;

export const LinkStyled = styled(Link)`
  color: #1677ff;
  text-decoration: none;
`
