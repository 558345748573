export const formatData = (array = [], options) => {
  if (options?.add) {
    array = [...array, ...options?.add];
  }

  const entities = array.reduce((result, product) => {
    if (options?.delete && options?.delete?.includes(options?.deleteKey ? product?.[options?.deleteKey] : product?.id)) {
      return result;
    }

    if (options?.update && options?.update.id === product.id) {
      product = {
        ...product,
        ...options?.update,
      };
    }

    return {
      ...result,
      [product.id]: {
        ...product,
        productType: product?.cake_type,
        url: `${process.env.REACT_APP_API_UPLOADS_URL}/cakes/${options?.uploadsPath || ''}${product.photo_uri}`,
        thumbUrl: `${process.env.REACT_APP_API_UPLOADS_URL}/cakes/thumb/${options?.uploadsPath || ''}${product.photo_uri}`,
      },
    };
  }, {});

  
  const ids = Object.keys(entities).map((id) => Number(id)) || [];


  ids.sort(function (a, b) {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });

  const data = ids.map((id) => entities[id]);

  return {
    entities,
    ids,
    data,
  };
};
