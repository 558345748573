import { createAsyncThunk } from '@reduxjs/toolkit';

import { formatData } from 'utils/product';
import { createApiRequest } from 'utils/request';

export const getProducts = createAsyncThunk('product/getProducts', async (params, thunkApi) => {
  const response = await createApiRequest(
    {
      url: `/products`,
      params,
    },
    thunkApi
  );

  return {
    page: response?.data?.page,
    total: response?.data?.total,
    ...formatData(response?.data?.data || []),
  };
});

export const addProduct = createAsyncThunk(
  'product/addProduct',
  async ({ successCallback = () => {}, failureCallback = () => {}, ...params }, thunkApi) => {
    const products = thunkApi.getState().product.data;

    try {
      const response = await createApiRequest(
        {
          method: 'post',
          url: '/api/v1/product',
          data: params,
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
        thunkApi
      );

      successCallback(response?.data?.data[0]);

      return formatData(products, { add: response?.data?.data || [] });
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const updateProduct = createAsyncThunk('product/updateProduct', async ({ id, ...params } = {}, thunkApi) => {
  try {
    const response = await createApiRequest(
      {
        method: 'put',
        url: `/api/v1/product/${id}`,
        data: params,
      },
      thunkApi
    );

    if (response?.status === 200) {
      return { id, ...params };
    }

    return false;
  } catch (error) {
    return false;
  }
});

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async ({ id, photo_uri, successCallback = () => {}, failureCallback = () => {} } = {}, thunkApi) => {
    const products = thunkApi.getState().product.data;
    await createApiRequest(
      {
        method: 'delete',
        url: `/api/v1/product/${id}`,
        params: { photo_uri },
      },
      thunkApi
    );

    successCallback();

    return formatData(products, { delete: [id] });
  }
);

export const deleteProducts = createAsyncThunk(
  'product/deleteProducts',
  async ({ productsIds, successCallback = () => {}, failureCallback = () => {} } = {}, thunkApi) => {
    const products = thunkApi.getState().product.data;

    await createApiRequest(
      {
        method: 'post',
        url: `/api/v1/product/bulkDelete`,
        data: { productsIds },
      },
      thunkApi
    );

    successCallback();

    return formatData(products, { delete: productsIds, deleteKey: 'photo_uri' });
  }
);
