import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Login, Products, Prices, Error, CronJobsPage, Fillings, Content } from 'pages';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Notify } from 'components/Notify';

export const App = () => {
  return (
    <>
      <Notify />
      <Router>
        <Routes>
          <Route path='/login' element={<Login title='Сторінка входу' />} />
          <Route path='/' element={<ProtectedRoute component={<Login title='Сторінка входу' />} navigateTo='/login' />} />
          <Route path='/prices' element={<ProtectedRoute component={<Prices title='Ціни' />} navigateTo='/prices' />} />
          <Route path='/cakes' element={<ProtectedRoute component={<Products title='Торти' />} navigateTo='/cakes' />} />
          <Route path='/fillings' element={<ProtectedRoute component={<Fillings title='Начинки' />} navigateTo='/fillings' />} />
          <Route path='/content' element={<ProtectedRoute component={<Content title='Тексти' />} navigateTo='/content' />} />
          <Route path='/cron' element={<ProtectedRoute component={<CronJobsPage title='Крон' />} navigateTo='/cron' />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>
    </>
  );
};
