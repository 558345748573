import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Progress } from 'components/shared/Progress';
import { Button } from 'components/shared/Button';

import { ModalStyled, IconCloseStyled, ButtonsWrapper, ModalContent, ModalWrapper } from './ModalStyled';

export const Modal = ({
  progress = null,
  onSubmit = () => {},
  onReset,
  onClose,
  resetButtonProps = {},
  submitButtonProps = {},
  children,
  contentCenter = false,
  submitText = null,
  visible = false,
  withClose = false,
}) => {
  const handleDisableClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === 'Escape' ? onClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onClose]);

  const submitButtonText = submitText || 'Завантажити';

  return ReactDOM.createPortal(
    <ModalStyled isVisible={visible} onClick={onClose}>
      <ModalWrapper onClick={handleDisableClick}>
        <IconCloseStyled name="close" onClick={onClose} isHovered />

        {!progress ? null : <Progress percent={progress} />}

        <ModalContent contentCenter={contentCenter}>{children}</ModalContent>

        <ButtonsWrapper>
          {!withClose ? null : (
            <Button {...resetButtonProps} styleType='secondary' onClick={onClose}>
              Закрити
            </Button>
          )}

          {!onReset ? null : (
            <Button {...resetButtonProps} styleType='danger' onClick={onReset}>
              Скинути
            </Button>
          )}
          <Button onClick={onSubmit} {...submitButtonProps}>
            {submitButtonText}
          </Button>
        </ButtonsWrapper>
      </ModalWrapper>
    </ModalStyled>,
    document.body
  );
};
