export const PAGES = {
  home: {
    title: 'Головна',
  },
  cakes: {
    title: 'Торти',
  },
  prices: {
    title: 'Ціни',
  },
  contacts: {
    title: 'Контакти',
  },
};
