import { LoginForm } from '../../components/LoginForm';
import { Wrapper } from './LoginPageStyled';

export const Login = () => {
  return (
    <Wrapper>
      <h2>Вхід до особистого кабінету</h2>
      <LoginForm />
    </Wrapper>
  );
};
