import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getPrices, updatePrices } from 'api/pricesApi';

const initialState = {
  data: [],
  loading: false,
  errorMessage: null,
  entities: {},
  ids: [],
  errors: {},
};

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  extraReducers: {
    [updatePrices.pending]: (state) => ({ ...state, loading: true }),
    [updatePrices.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    },
    [updatePrices.rejected]: (state, { error }) => {
      return { ...state, errorMessage: error?.message || null, loading: false };
    },

    // GET
    [getPrices.pending]: (state) => ({ ...state, loading: true }),
    [getPrices.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },
    [getPrices.rejected]: (state, { payload }) => {
      return { ...state, errors: payload?.errors, loading: false };
    },
  },
});

const pricesState = (state) => state.prices;

export const getPricesStateSelector = createSelector(pricesState, (state) => state);

export const getIsLoadingSelector = createSelector(pricesState, ({ loading }) => loading);

export const getPricesSelector = createSelector(pricesState, (state) => state?.data);

export const pricesReducer = pricesSlice.reducer;
