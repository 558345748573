import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getContent } from 'api/contentApi';

const initialState = {
  data: [],
  loading: false,
  errorMessage: null,
  entities: {},
  ids: [],
  errors: {},
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  extraReducers: {
    [getContent.pending]: (state) => ({ ...state, loading: true }),
    [getContent.fulfilled]: (state, { payload }) => {
      if (payload?.error) {
        return state;
      }

      state.entities = payload?.reduce((state, data) => {
        return {
          ...state,
          [data.id]: data,
        };
      }, {});

      state.data = payload;
      state.loading = false;
      state.ids = payload.map(({ id }) => id);
    },
    [getContent.rejected]: (state, error) => {
      state.loading = false;
      state.errorMessage = error?.message;
    },
  },
});

const contentState = (state) => state.content;

export const getContentSelector = createSelector(contentState, (state) => state);

export const getIsContentLoadedSelector = createSelector(contentState, ({ loading }) => loading);

export const contentReducer = contentSlice.reducer;
