import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FillingsList } from 'components/FillingsList/FillingsList';
import { PageContainer } from 'components/PageContainer';
import { Loader } from 'components/shared/Loader';

import { getFillings } from 'api/fillingsApi';

import { getFillingsStateSelector, getIsFillingsLoadedSelector } from 'store/fillingsSlice';

export const Fillings = ({ title }) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const { data } = useSelector(getFillingsStateSelector);
  const isLoading = useSelector(getIsFillingsLoadedSelector);

  useEffect(() => {
    dispatch(getFillings());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoaded(!isLoading);
  }, [isLoading]);

  if (!isLoaded && isLoading && !data?.length) {
    return <Loader isVisible />;
  }

  return (
    <PageContainer title={title}>
      <FillingsList />
    </PageContainer>
  );
};
