import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  errorMessage: null,
  entities: {},
  ids: [],
  errors: {},
};

export const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    addNotify: (state, action) => {
      state.data.push(action.payload);
    },
    removeNotify: (state, action) => {
      state.data = state.data.filter((notify) => notify.id !== action.payload);
    },
  },
});

const notifyState = (state) => state.notify;

export const getNotifySelector = createSelector(notifyState, (state) => state);

export const notifyReducer = notifySlice.reducer;

export const { addNotify, removeNotify } = notifySlice.actions;

export default notifySlice.reducer;
