import styled from 'styled-components';

export const NotifyContainerStyled = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
`;

export const NotifyStyled = styled.div`
  margin: 15px;
  padding: 15px;
  background: aquamarine;
  border-radius: 5px;
  box-shadow: 0px 0 10px -1px rgba(0, 0, 0, 0.2);
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
