import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PageContainer } from 'components/PageContainer';
import { Loader } from 'components/shared/Loader';

import { getContent, saveContent } from 'api/contentApi';

import { getContentSelector, getIsContentLoadedSelector } from 'store/contentSlice';

import { PAGES } from 'constants/global';
import { Input } from 'components/shared/Input';
import { Textarea } from 'components/shared/Textarea';
import { Button } from 'components/shared/Button';

export const Content = ({ title }) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [formState, setFormState] = useState({});

  const { data } = useSelector(getContentSelector);
  const isLoading = useSelector(getIsContentLoadedSelector);

  useEffect(() => {
    dispatch(getContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoaded(!isLoading);

    const form = data.reduce((result, content) => {
      result[content.contentId] = {
        title: content.title,
        description: content.description,
      };

      return result;
    }, {});
    setFormState(form);
  }, [isLoading]);

  if (!isLoaded && isLoading && !data?.length) {
    return <Loader isVisible />;
  }

  const handleSubmit = () => {
    dispatch(saveContent({ params: formState, successCallback: () => setIsDataUpdated(true) }));
  };

  const handleFormChange =
    ({ type, contentId }) =>
    ({ target: { value } }) => {
      setFormState({
        ...formState,
        [contentId]: {
          ...formState[contentId],
          [type]: value,
        },
      });
    };

  return (
    <PageContainer title={title}>
      {isDataUpdated && <h1>Data updated</h1>}
      {data.map(({ id, contentId }) => {
        return (
          <div key={id}>
            <h1>{PAGES[contentId]?.title}</h1>
            <Input
              placeholder='Заголовок'
              value={formState?.[contentId]?.title}
              onChange={handleFormChange({ type: 'title', contentId })}
            />
            <Textarea
              placeholder='Текст'
              value={formState?.[contentId]?.description}
              onChange={handleFormChange({ type: 'description', contentId })}
            />
          </div>
        );
      })}
      <Button onClick={handleSubmit}>Сохранить</Button>
    </PageContainer>
  );
};
