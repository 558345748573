import * as icons from 'assets/icons';

import { IconWrapper, IconStyled } from './IconStyled';

export const Icon = ({ name = null, color = '#767676', size = 16, isHovered = false, hoverColor = '#1677ff', onClick, ...props }) => {
  const isClickable = !!onClick;
  return icons?.[name] ? (
    <IconWrapper onClick={onClick} {...props}>
      <IconStyled as={icons?.[name]} {...{ color, size, hover: hoverColor, hovered: isHovered, clickable: isClickable }} />
    </IconWrapper>
  ) : null;
};
