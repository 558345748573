import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getNotifySelector, removeNotify } from 'store/notifySlice';

import { NotifyStyled, NotifyContainerStyled } from './NotifyStyled';

export const Notify = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);

  const { data: notices } = useSelector(getNotifySelector);

  const activeNotice = notices.find((notice) => notice.isActive);

  useEffect(() => {
    if (notices.length && activeNotice) {
      setVisible(true);

      setTimeout(() => {
        setVisible(false);

        dispatch(removeNotify(activeNotice.id));
      }, 2000);
    }
  }, [activeNotice, dispatch, notices]);

  return (
    <NotifyContainerStyled>
      {notices.map(({ text }, index) => (
        <NotifyStyled key={text} visible={visible} index={index}>
          {text}
        </NotifyStyled>
      ))}
    </NotifyContainerStyled>
  );
};
