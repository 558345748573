import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProductsList } from 'components/ProductsList';
import { PageContainer } from 'components/PageContainer';
// import { InfiniteScrollList } from 'components/InfiniteScroll';
import { Loader } from 'components/shared/Loader';

import { getProducts } from 'api/productsApi';

import { getProductSelector, getIsProductLoadedSelector } from 'store/productSlice';

export const Products = ({ title }) => {
  const limit = 1;
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const { products, page, total } = useSelector(getProductSelector);
  const isLoading = useSelector(getIsProductLoadedSelector);

  useEffect(() => {
    if (!products.length) {
      dispatch(getProducts({ page: 1, limit, all: 1 }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoaded(!isLoading);
  }, [isLoading]);

  const handleFetchData = ({ isNeedToLoadMore = true }) => {
    const newPage = isNeedToLoadMore ? +page + 1 : page;
    dispatch(getProducts({ page: newPage, all: 1 }));
  };

  // const isEndMessage = total > limit ? <>Це все :)</> : null;
  // const hasMore = products.length < total;
  // const loaderMessage = <p>Завантажую ще...</p>;

  const isNoProducts = !products?.length;

  if (!isLoaded && isLoading && !products.length) {
    return <Loader isVisible />;
  }

  return (
    <PageContainer title={title}>
      {/* // <InfiniteScrollList
        //   dataLength={products.length}
        //   next={handleFetchData}
        //   hasMore={hasMore}
        //   loader={loaderMessage}
        //   endMessage={isEndMessage}
        //   pullDownToRefresh
        //   scrollThreshold={0.8}
        //   refreshFunction={() => {}}
        // >
        // </InfiniteScrollList> */}

      <ProductsList items={products} onDelete={handleFetchData} total={total} isNoProducts={isNoProducts} />
    </PageContainer>
  );
};
