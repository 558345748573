import { useDispatch } from 'react-redux';

import { addProduct } from 'api/productsApi';

export const useUploadFile = ({ file, cakeType }) => {
  const dispatch = useDispatch();

  if (!file && !cakeType) {
    return {};
  }

  const onUpload = ({ successCallback = () => {}, failureCallback = () => {} } = {}) => {
    dispatch(
      addProduct({
        image: file,
        cakeType,
        failureCallback,
        successCallback,
      })
    );
  };

  return {
    onUpload,
  };
};
