import { ButtonStyled, IconStyled } from './ButtonStyled';

export const Button = ({ styleType = 'primary', children, icon = null, ...props }) => {
  return (
    <ButtonStyled styleType={styleType} {...props} withIcon={!!icon} hasChildren={!!children}>
      {icon ? <IconStyled name={icon} /> : null}
      {children ? children : null}
    </ButtonStyled>
  );
};

