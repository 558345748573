import { useState, useEffect } from 'react';

import { getJobs, /* createJob, */ deleteJob, changeJob } from 'api/cronApi';

import { Job } from './components/Job';

export const CronJobs = () => {
  //   const [params, setParams] = useState({});
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);

  const getJobsList = async () => {
    try {
      const response = await getJobs();

      const sortedList = response?.data?.jobs.sort(({ jobId: prevJobId }, { jobId: nextJobId }) => +prevJobId - +nextJobId);
      console.log(sortedList);
      setJobs(sortedList);

    } catch (error) {
      setError(error?.message || 'Error');
    }
  };

  useEffect(() => {
    getJobsList();
  }, []);

  //   const handleCreate = () => {
  //     createJob(params).then(({ status }) => {
  //       if (status === 200) {
  //         getJobsList();
  //         setParams(null);
  //       }
  //     });
  //   };

  const handleDelete =
    ({ id }) =>
    () => {
      deleteJob({ id }).then(({ status }) => {
        if (status === 200) {
          getJobsList();
        }
      });
    };

  const handleChange = (params) => () => {
    changeJob(params).then(({ status }) => {
      if (status === 200) {
        getJobsList();
      }
    });
  };

  //   const handleSetParams = ({ target }) => {
  //     setParams({
  //       ...params,
  //       [target.name]: target.value,
  //     });
  //   };

  return (
    <>
      {!jobs?.length || error ? null : jobs.map((job) => <Job key={job.jobId} onDelete={handleDelete} onChange={handleChange} {...job} />)}
    </>
  );
};
