import axios from 'axios';

import { getItemStorage } from 'utils/localStorage';

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer fP4C7ObzkWMzNtZbDNBvKgHDvKbnw/H8B4xZLciheU8=',
};

export const getJobs = () =>
  axios.get('https://api.cron-job.org/jobs', {
    headers,
  });

export const getJobDetails = ({ id }) =>
  axios.get(`https://api.cron-job.org/jobs/${id}`, {
    headers,
  });

export const createJob = async ({ id = 138, ...params } = {}) =>
  axios.put(
    'https://api.cron-job.org/jobs',
    {
      job: {
        enabled: false,
        title: `Update product visibility ${id}`,
        url: `https://api.mycake.kiev.ua/api/productPublish`,
        type: 0,
        requestMethod: 1,
        schedule: {
          timezone: 'Europe/Kiev',
          hours: [0],
          mdays: [-1],
          minutes: [0],
          months: [-1],
          wdays: [-1],
        },

        extendedData: {
          body: JSON.stringify({
            id: 138,
            ...params,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getItemStorage('userToken')}`,
          },
        },
      },
    },
    {
      headers,
    }
  );

export const deleteJob = async ({ id }) =>
  await axios({
    method: 'delete',
    url: `https://api.cron-job.org/jobs/${id}`,
    headers,
  });

export const changeJob = async ({ id, ...params }) =>
  await axios({
    method: 'patch',
    url: `https://api.cron-job.org/jobs/${id}`,
    data: {
      job: params,
    },
    headers,
  });
