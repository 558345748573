import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { PageContainer } from 'components/PageContainer';
import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';
import { Box } from 'components/shared/Box';
import { Loader } from 'components/shared/Loader';

import { getPrices, updatePrices } from 'api/pricesApi';

import { getPricesSelector, getIsLoadingSelector } from 'store/pricesSlice';

import { PRODUCTS_TYPES_GROUP } from 'constants/products';

export const Prices = ({ title }) => {
  const dispatch = useDispatch();

  const [prices, setPrices] = useState({});
  const [status, setStatus] = useState(null);
  const [errors, setErrors] = useState(null);

  const pricesData = useSelector(getPricesSelector);
  const isLoading = useSelector(getIsLoadingSelector);

  const handlePriceChange =
    (key) =>
    ({ target }) => {
      setPrices({
        ...prices,
        [key]: +target?.value || null,
      });
    };

  const handleSave = useCallback(() => {
    setStatus(null);

    dispatch(
      updatePrices({
        params: prices,
        successCallback: ({ status }) => {
          setErrors(null);
          setStatus(status);

          setTimeout(() => {
            setStatus(null);
          }, 3000);
        },
        failureCallback: ({ errors }) => {
          setErrors(errors);
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices]);

  useEffect(() => {
    dispatch(getPrices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPrices(pricesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricesData]);

  if (isLoading && !prices) {
    return <Loader isVisible />;
  }

  return (
    <PageContainer title={title}>
      <Box display='flex' gap='20px' flexWrap='wrap'>
        {Object.keys(PRODUCTS_TYPES_GROUP).map((key) => (
          <Box gap='10px' marginBottom='10px' width='100%' maxWidth='250px' key={key} alignItems='center'>
            <Box fontWeight='600' flexShrink='0'>
              {PRODUCTS_TYPES_GROUP[key]}:
            </Box>
            <Input
              disabled={isLoading}
              name={key}
              value={prices[`price_${key}`]}
              onChange={handlePriceChange(`price_${key}`)}
              after='грн'
            />
            {errors ? <p>{errors?.[`price_${key}`]?.[0]}</p> : null}
          </Box>
        ))}
      </Box>

      {status && status === 'success' ? <p>Все чудово! Дані збережено!</p> : null}
      <Button onClick={handleSave}>Зберегти</Button>
    </PageContainer>
  );
};
