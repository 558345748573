import { useDispatch, useSelector } from 'react-redux';
import { useState, memo } from 'react';

import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';

import { deleteFilling, updateFilling } from 'api/fillingsApi';

import { VISIBILITY_TYPES, VISIBILITY_TYPES_TITLES } from 'constants/products';

import { Wrapper, Item, ItemName, ImageWrapper, ItemActions, ItemDescription, ItemValue } from './FillingsListStyled';
import { getFillingsSelector } from 'store/fillingsSlice';
import { Icon } from 'components/shared/Icon';
import { Box } from 'components/shared/Box';
import { AddFillingModal } from 'components/AddFillingModal';

export const FillingsList = memo(() => {
  const dispatch = useDispatch();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [initialModalValues, setInitialModalValues] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const { fillings } = useSelector(getFillingsSelector);
  const { errorMessage } = useSelector(getFillingsSelector);

  const handleDelete = () => {
    const { id, photo_uri } = editItem;

    dispatch(
      deleteFilling({
        id,
        photo_uri,
        successCallback: () => {
          setIsDeleteModalVisible(false);
        },
      })
    );
  };

  const handleVisibilityChange =
    ({ id, visibility }) =>
    () => {
      const isVisible = visibility === VISIBILITY_TYPES.visible;

      dispatch(
        updateFilling({
          id,
          visibility: isVisible ? VISIBILITY_TYPES.hidden : VISIBILITY_TYPES.visible,
        })
      );
    };

  const handleDeleteModalOpen = (item) => () => {
    setIsDeleteModalVisible(true);
    setEditItem(item);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalVisible(false);
  };

  const handleAddModalClose = () => {
    setIsAddModalVisible(false);
    setInitialModalValues(null);
  };

  const handleEdit = (item) => () => {
    setEditItem(item);
    console.log('item', item);
    const { id, title, description, url, visibility, photo_uri } = item;
    setInitialModalValues({ id, title, description, url, visibility, photo_uri });
    setIsAddModalVisible(true);
  };

  const getVisibilityButtonText = (visible) => (visible === VISIBILITY_TYPES.hidden ? 'Показати' : 'Приховати');
  const visibilityIcon = (visible) => (visible === VISIBILITY_TYPES.hidden ? 'visibilityOff' : 'eye');

  return (
    <Wrapper>
      <AddFillingModal isVisible={isAddModalVisible} initialValues={initialModalValues} onClose={handleAddModalClose} />
      {errorMessage ? <p>{errorMessage}</p> : null}
      {!fillings.length ? (
        <p>Немає начинок</p>
      ) : (
        fillings.map((item, index) => {
          return (
            <Item key={index}>
              <ImageWrapper>
                <img alt='' src={item.url} />
              </ImageWrapper>

              <ItemDescription>
                <ItemName>
                  Назва:
                  <ItemValue>{item.title}</ItemValue>
                </ItemName>
                <ItemName>
                  Опис:
                  <ItemValue>{item.description || '-'}</ItemValue>
                </ItemName>
                <ItemName>
                  Видимість на сайті: <ItemValue>{VISIBILITY_TYPES_TITLES[item?.visibility]}</ItemValue>{' '}
                  <Icon
                    title={getVisibilityButtonText(item?.visibility)}
                    name={visibilityIcon(item?.visibility)}
                    onClick={handleVisibilityChange(item)}
                  />
                </ItemName>
              </ItemDescription>

              <ItemActions>
                <Button icon='edit' onClick={handleEdit(item)} title='Редагувати' />
                <Button styleType='danger' icon='delete' onClick={handleDeleteModalOpen(item)} title='Видалити' />
              </ItemActions>
            </Item>
          );
        })
      )}

      <Modal
        visible={isDeleteModalVisible}
        withClose
        contentCenter
        submitText='Так'
        onClose={handleDeleteModalClose}
        onSubmit={handleDelete}
      >
        <Box flexDirection='column'>
          <p>
            Видалити начінку&nbsp;<strong>{editItem?.title}</strong>.
          </p>
          <p>Ви впевнені?</p>
        </Box>
      </Modal>
    </Wrapper>
  );
});
