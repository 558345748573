import styled from 'styled-components';

export const ButtonsStyled = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

export const Status = styled.span`
  background-color: ${({ isActive }) => (isActive ? 'var(--color-green)' : 'var(--color-danger)')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
`;
