import styled from 'styled-components';
import { Icon } from 'components/shared/Icon';

export const ModalStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 5;
  top: 0;
  left: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const ModalWrapper = styled.div`
  background-color: var(--color-white);
  padding: 20px 0 10px;
  border-radius: 3px;
  position: relative;
  min-height: 240px;
  min-width: 300px;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ModalContent = styled.div`
  padding: 0 15px 15px;
  flex: 1;
  
  ${({contentCenter}) => !contentCenter ? null : `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  border-top: 1px solid var(--color-border);
  display: flex;
  padding: 10px 15px 0;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  button {
    width: 100%;
  }
`;

export const IconCloseStyled = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
`;
