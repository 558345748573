import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAction: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserAction } = userSlice.actions;

export const getCurrentUserSelector = (state) => state.user.currentUser;

export const userReducer = userSlice.reducer;
