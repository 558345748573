import { createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequest } from 'utils/request';

export const getContent = createAsyncThunk('content/getContent', async (params, thunkApi) => {
  const response = await createApiRequest(
    {
      url: `/api/content`,
    },
    thunkApi
  );

  return response?.data?.data || [];
});

export const saveContent = createAsyncThunk('content/saveContent', async ({ successCallback = () => {}, params }, thunkApi) => {
  try {
    const response = await createApiRequest(
      {
        method: 'put',
        url: `/api/v1/content`,
        data: params,
      },
      thunkApi
    );

    successCallback(response?.data);

    return response?.data || false;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
});
