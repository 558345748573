import { createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequest } from 'utils/request';

export const getPrices = createAsyncThunk('prices/getPrices', async (params, { rejectWithValue }, thunkApi) => {
  const response = await createApiRequest(
    {
      url: `/prices`,
      params,
    },
    thunkApi
  );

  if (response.status < 200 || response.status >= 300) {
    return rejectWithValue(response?.data);
  }

  return response?.data;
});

export const updatePrices = createAsyncThunk(
  'prices/updatePrices',
  async ({ params, failureCallback = () => {}, successCallback = () => {} }, thunkApi) => {
    try {
      const response = await createApiRequest(
        {
          url: `/api/v1/prices`,
          method: 'put',
          data: params,
        },
        thunkApi
      );

      if (response?.data) {
        successCallback(response?.data);
      }

      return response?.data;
    } catch (error) {
      if (error.message === 'Unauthenticated.') {
        window.location.reload();
      }

      failureCallback(error.response.data?.data);
    }
  }
);
