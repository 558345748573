import { useNavigate } from 'react-router';

import { useUserAuth } from 'hooks/useUserAuth';

import { Wrapper, LinkStyled } from './HeaderStyled';
import { Button } from 'components/shared/Button';

export const Header = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logOut({
      successCallback: () => {
        navigate('/');
      },
    });
  };

  return (
    <Wrapper>
      <p>{user && user.email}</p>
      <LinkStyled to='/cakes'>Торти</LinkStyled>
      <LinkStyled to='/prices'>Ціни</LinkStyled>
      <LinkStyled to='/fillings'>Начинки</LinkStyled>
      <LinkStyled to='/content'>Тексти</LinkStyled>
      <LinkStyled to='/cron'>Cron</LinkStyled>

      <Button onClick={handleLogOut} styleType="secondary" icon="logout" title="Вийти" />
    </Wrapper>
  );
};
