import axios from 'axios';
import { getItemStorage } from 'utils/localStorage';

import { setUserAction } from 'store/userSlice';
import { logout } from 'store/authSlice';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const createApiRequest = async ({ url = null, method = 'get', headers = {}, ...props }, thunkApi) => {
  if (!url) {
    return;
  }

  let response = null;

  try {
    response = await instance({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getItemStorage('userToken')}`,
        ...headers,
      },
      ...props,
    });
  } catch (error) {
    if (error.response.status === 401) {
      thunkApi.dispatch(logout());
      thunkApi.dispatch(setUserAction());
    }

    response = error;
  }

  return response;
};
