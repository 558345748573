import styled, { css } from 'styled-components';

export const LoaderStyled = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const LoaderContent = styled.div``;
