import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserAuth } from 'hooks/useUserAuth';

// import {apiRegister} from 'api/authApi'

import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';

import { Wrapper, FormStyled, ErrorMessage } from './LoginFormStyled';

export const LoginForm = () => {
  const navigate = useNavigate();
  const { logIn } = useUserAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    logIn({
      email,
      password,
      successCallback: () => {
        navigate('/cakes');
      },
      errorCallback: ({message}) => {
        setError(message);
      },
    });
  };


  // const handleRegister = () => {
  //   apiRegister({
  //     email: 'msalsergey@gmail.com',
  //     password: 'M58s14al85M',
  //     name: 'Serhii'
  //   })
  // }

  return (
    <Wrapper>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}{' '}
      {/* <Button onClick={handleRegister}>Reg</Button> */}
      <FormStyled autoComplete='true'>
        <Input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
        <Input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
        <Button onClick={handleSubmit} type='button'>
          Submit
        </Button>
      </FormStyled>
    </Wrapper>
  );
};
