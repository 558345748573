export const PRODUCT_TYPES = {
  cake: 'cake',
  'cake-fruit': 'cake-fruit', // TODO: old name in the DB
  cupcake: 'cupcake',
  cupcakes: 'cupcakes',
  makaron: 'makaron',
  muss: 'cake_muss',
  cakeCheese: 'cake_cheese',
  cakeVelour: 'cake_velour',
  set: 'set',
  miniMuss: 'mini_muss',
};

export const PRODUCT_TYPES_LABELS = {
  [PRODUCT_TYPES.cake]: 'Торт',
  [PRODUCT_TYPES["cake-fruit"]]: 'Торт',
  [PRODUCT_TYPES["cake-muss"]]: 'Мусовий торт',
  [PRODUCT_TYPES.cupcake]: 'Капкейки',
  [PRODUCT_TYPES.cupcakes]: 'Капкейки',
  [PRODUCT_TYPES.makaron]: 'Макарон',
  [PRODUCT_TYPES.muss]: 'Мусовий',
  [PRODUCT_TYPES.cakeCheese]: 'Крем-чиз',
  [PRODUCT_TYPES.cakeVelour]: 'Велюр',
  [PRODUCT_TYPES.set]: 'Набір',
  [PRODUCT_TYPES.miniMuss]: 'Міні тістечка',
};

export const PRODUCTS_TYPES_GROUP = {
  [PRODUCT_TYPES.cakeCheese]: 'Крем-чиз',
  [PRODUCT_TYPES.cakeVelour]: 'Велюр',
  [PRODUCT_TYPES.muss]: 'Мусовий',
  [PRODUCT_TYPES.cupcakes]: 'Капкейки',
  [PRODUCT_TYPES.makaron]: 'Макарон',
  [PRODUCT_TYPES.set]: 'Набір',
  [PRODUCT_TYPES.miniMuss]: 'Міні тістечка',
}

export const DEFAULT_PRODUCT_TYPE = PRODUCT_TYPES.cakeCheese;

export const VISIBILITY_TYPES = {
  visible: 'visible',
  hidden: 'hidden',
};

export const VISIBILITY_TYPES_TITLES = {
  [VISIBILITY_TYPES.visible]: 'Видимий',
  [VISIBILITY_TYPES.hidden]: 'Прихований',
};
